import { useState } from "react";
import { Formik, Form, Field } from "formik";
import { validateEmail } from "../../../utils/validations";
import axios from "axios";
import { URL_BACKEND } from "../../../utils/const";

function SubscribeToEmail() {
  const [isSended, setIsSended] = useState(false);
  const [error, setError] = useState(false);

  function SubscribeToEmailForm() {
    return (
      <>
        {error && (
          <p style={{ color: "red", fontSize: "12px" }}>
            Ошибка. Попробуйте еще раз.
          </p>
        )}

        <Formik
          initialValues={{
            from_email: "",
          }}
          onSubmit={async (values, { resetForm }) => {
            try {
              await axios.post(`${URL_BACKEND}api/forms`, {
                ...values,
                from_project: 9,
                type_id: 3,
              });
              setError(false);
              setIsSended(true);
              resetForm();
            } catch (error) {
              setError(true);
              console.log("Error:", error);
            }
          }}
        >
          {({ errors, touched }) => {
            return (
              <Form className="form-inline">
                <Field
                  className="form-control"
                  id="from_email"
                  name="from_email"
                  type="email"
                  placeholder="E-mail"
                  validate={validateEmail}
                />
                <button
                  type="submit"
                  id="subscriber-send"
                  className="btn btn-primary"
                >
                  Подписаться
                </button>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  }

  function SubscribeToEmailFormSuccess() {
    return (
      <>
        <p style={{ color: "#fff", fontSize: "12px" }}>
          Вы успешно подписались на рассылку!
        </p>
      </>
    );
  }

  return !isSended ? SubscribeToEmailForm() : SubscribeToEmailFormSuccess();
}

export default SubscribeToEmail;
