export const URL_BACKEND = "https://chg-mall.ru/";

export const QUERY_ROUTE = {
  NEWS: "api/fond/news/",
  PROMOTIONS: "api/fond/akcia",
  PROMOTION_ITEM: "api/fond/akcia/",
  REPORTS: "api/fond/otchet",
  REVIEWS: "api/fond/otzyvs",
  FORMS: "api/forms",
  YOOKASSA: "api/fond/card/payment",
};

export const KEY_GOOGLE_RECAPTCHA = "6Le0HK4bAAAAACCZRkTTMsnNRQwcBgUl-m9I5xAA";

export const MONTH_ARRAY = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];
