const LoaderPromotion = (props) => {
  return (
    <>
      <div className="articles-wrap">
        {Array.from(Array(props.count).keys()).map((el) => {
          return (
            <div key={el} className="article-item">
              <div className="loading-promotion"></div>
            </div>
          );
        })}
      </div>

      <div className="pagination__loading">
        <div></div>
      </div>
    </>
  );
};

export default LoaderPromotion;
