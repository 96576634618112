import {
  FETCH_REPORTS_ERROR,
  FETCH_REPORTS_START,
  FETCH_REPORTS_SUCCESS,
} from "../actions/actionTypes";

const initialState = {
  reports: [],
  loading: false,
  error: null,
};

export default function reportsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_REPORTS_START:
      return {
        // ...state,
        loading: true,
      };
    case FETCH_REPORTS_SUCCESS:
      return {
        ...state,
        reports: action.reports,
        loading: false,
      };
    case FETCH_REPORTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
