import React from 'react';
import {Link} from "react-router-dom";

const PaymentsPopup = (props) => {
    return (
        <div className={props.isOpen ? `popup popup_opened` : `popup`}>
            <div className="popup__content">
                <div className="popup__close" onClick={props.onClose} />
                <h4>Выберите способ оплаты</h4>
                <a href="https://qr.nspk.ru/AS1A003J93UQEA5C9EBQFLLD1LVSK6BM?type=01&bank=100000000111&crc=1EF7" className="btn btn-primary donate__button popup__button">Оплатить через приложение банка</a>
                <Link to="/pages/help_7" onClick={props.onClose} className="btn btn-primary donate__button popup__button">Оплатить по карте</Link>
                <Link to="/pages/help_3" className="btn btn-primary donate__button popup__button" onClick={props.onClose}>Другие способы оплаты</Link>
            </div>
        </div>
    );
};

export default PaymentsPopup;
