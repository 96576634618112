import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {fetchPromotions} from "../../store/actions/promotions";
import ItemHeplNeed from "./ItemHeplNeed";
import {Link, useLocation, withRouter} from "react-router-dom";
import LoaderPromotion from "../Loader/LoaderPromotion";
import {getActivePage} from "../../utils/utils";

const HelpNeed = ({isMainPage, fetchPromotions, status, items, loading}) => {
    const location = useLocation()
    const [activePage, setActivePage] = useState(getActivePage(location.search))
    const itemsPerPage = isMainPage ? 4 : 50
    if (activePage !== getActivePage(location.search)) {
        setActivePage(getActivePage(location.search))
    }

    useEffect(() => {
        fetchPromotions(activePage, itemsPerPage, status)
    }, [activePage, fetchPromotions, itemsPerPage, status])

    const cls = ["articles"];

    if (isMainPage) {
        cls.push("articles-main");
    }

    return (
        <div className={cls.join(" ")}>
            {loading && items.length === 0 ?
                <LoaderPromotion count={4}/> :
                <>
                    <div className="articles-wrap">
                        {items.map((item, i) => {
                            return (
                                <ItemHeplNeed delay={i} key={item.id} item={item}/>
                            )
                        })}
                    </div>
                    {isMainPage && <Link className="article-link" to="/they-need-help">Перейти к остальным сборам</Link>}
{/*                    {props.countPages > 1 ?
                        <Pagination
                            activePage={props.activePage}
                            pageCounts={props.countPages}
                        /> : null}*/}
                </>
            }
        </div>
    );
}

function mapStateToProps(state) {
    return {
        items: state.promotions.promotions || [],
        loading: state.promotions.loading,
        activePage: state.promotions.activePage,
        countPages: state.promotions.countPages,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchPromotions: (page, itemsPerPage, status) =>
            dispatch(fetchPromotions(page, itemsPerPage, status)),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(HelpNeed)
);
