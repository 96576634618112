export function validateEmail(value) {
  let error;
  if (!value) {
    error = "Email не может быть пустым";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = "Некорретный email";
  }
  return error;
}

export function validateReviewsName(value) {
  let error;
  if (!value) {
    error = "Имя не может быть пустым";
  }
  return error;
}

export function validateEmpty(value) {
  let error;
  if (!value) {
    error = "Поле не может быть пустым";
  }
  return error;
}

export function validateText(value) {
  let error;
  if (!value) {
    error = "Поле не может быть пустым";
  }
  return error;
}

export function validatePhone(value) {
  let error;
  if (!value) {
    error = "Телефон не может быть пустым";
  }
  return error;
}

export function validateRecaptcha(value) {
  let error;
  if (!value) {
    error = "Вы не прошли проверку";
  }
  return error;
}
