import { combineReducers } from "redux";
import newsReducer from "./news";
import promotionReducer from "./promotions";
import reportsReducer from "./reports";
import reviewsReducer from "./reviews";

export default combineReducers({
  promotions: promotionReducer,
  news: newsReducer,
  reviews: reviewsReducer,
  reports: reportsReducer,
});
