import {
  FETCH_NEWS_ERROR,
  FETCH_NEWS_ITEM_SUCCESS,
  FETCH_NEWS_PAGES_COUNT,
  FETCH_NEWS_START,
  FETCH_NEWS_SUCCESS,
} from "../actions/actionTypes";

const initialState = {
  news: [],
  activePage: 1,
  countPages: 1,
  loading: false,
  error: null,
  itemNews: null,
};

export default function newsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_NEWS_START:
      return {
        // ...state,
        loading: true,
      };
    case FETCH_NEWS_SUCCESS:
      return {
        ...state,
        news: action.news,
      };
    case FETCH_NEWS_ITEM_SUCCESS:
      return {
        ...state,
        itemNews: action.itemNews,
        loading: false,
      };
    case FETCH_NEWS_PAGES_COUNT:
      return {
        ...state,
        loading: false,
        activePage: action.payload.activePage,
        countPages: action.payload.pages,
      };
    case FETCH_NEWS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
