const LoaderNews = (props) => {
  return (
    <>
      {Array.from(Array(props.count).keys()).map((el) => {
        return (
          <div key={el} className="Chronology-item">
            <div className="loading-promotion"></div>
          </div>
        );
      })}
    </>
  );
};

export default LoaderNews;
