import React from 'react';
import './Header.css';
import videoBannerPc from "../../media/videos/bannerVideoPc.mp4";
import videoBannerMobile from "../../media/videos/bannerVideoMobile.mp4";
import logo from '../../media/img/blagfondLogo.png';
import {Link, withRouter} from "react-router-dom";
import {useLocation} from "react-router-dom/cjs/react-router-dom";
import hamburgerButton from '../../media/img/hamburger-button.svg';

const Header = ({windowWidth, handlePopupClick, handleMobileOpen}) => {
    const location = useLocation();


    const helloText = () => {
        if (location.pathname === "/") {
            return (
                <>
                    <span className="header__title-blue">Благотворительный</span>
                    <br/>
                    <span className="header__title-white">Фонд Чижова</span>
                </>
            );
        } else if (location.pathname === '/they-need-help' || location.pathname.startsWith('/reports')) {
            return (
                <>
                    <span className="header__title-blue">Благотворительный</span>
                    <br/>
                    <span className="header__title-blue">Фонд Чижова</span>
                </>
            )
        } else {
            return (
                <>
                    <span className="header__title-white">В наших силах</span>
                    <br/>
                    <span className="header__title-white">Подарить счастье</span>
                </>
            );
        }
    };

    const navlinkClassChanger = () => {
        if (location.pathname === '/they-need-help' || location.pathname.startsWith('/reports')) {
            return "header__nav-link_blue"
        } else {
            return "header__nav-link"
        }
    }

    const classnameChanger = () => {
        switch (location.pathname) {
            case '/pages/about':
                return 'header__about'
            case '/they-need-help':
                return 'header__theyNeedHelp'
            case '/news':
                return 'header__news'
            case '/pages/helpman':
                return 'header__helpman'
            case '/reports/in':
                return 'header__reports'
            case '/reports/out':
                return 'header__reports'
            case '/pages/to-help':
                return 'header__toHelp'
            case '/':
                return 'header'
            default:
                return 'header__about'
        }
    }

    return (
        <header className={classnameChanger()}>
            {location.pathname === '/' &&
                <video autoPlay muted loop className="header__video">
                    <source src={windowWidth > 925 ? videoBannerPc : videoBannerMobile} type="video/mp4"/>
                </video>
            }
            <div className="header__wrapper">
                <Link to="/"><img className={location.pathname === '/' ? 'header__logo' : 'header__logoSmall'}
                                  alt="логотип" src={logo}/></Link>
                <div className="header__info-wrapper">
                    {windowWidth > 1010 ?
                        < nav>
                            <ul className="header__nav-list">
                                <li>
                                    <Link className={navlinkClassChanger()} to="/pages/about">О фонде</Link>
                                </li>
                                <li>
                                    <Link className={navlinkClassChanger()} to="/they-need-help">Им нужна помощь</Link>
                                </li>
                                <li>
                                    <Link className={navlinkClassChanger()} to="/news">Новости</Link>
                                </li>
                                <li>
                                    <Link className={navlinkClassChanger()} to="/pages/helpman">Нам помогают</Link>
                                </li>
                                <li>
                                    <Link className={navlinkClassChanger()} to="/reports/in">Отчеты</Link>
                                </li>
                                <li>
                                    <Link className={navlinkClassChanger()} to="/pages/to-help">Помочь</Link>
                                </li>
                            </ul>
                        </nav> :
                        <div onClick={handleMobileOpen} className="header__burgerMenu">
                            Меню
                            <img alt="кнопка открытия меню" src={hamburgerButton} />
                        </div>
                    }
                    <div className="header__buttons-container">
                        <button onClick={handlePopupClick} className="header__button">Хочу помочь</button>
                        <Link to="/pages/needhelp" className="header__button">Мне нужна помощь</Link>
                    </div>
                    <a href='tel:+74732619999' className="header__phone">+7 (473) 261-99-99</a>
                    <div className="header__text-container">
                        <p className="header__text">Служба поддержки</p>
                        <p className="header__text">9:00-21:00 без выходных</p>
                    </div>
                </div>
            </div>
            <div
                className={location.pathname === '/' ? "header__title-wrapper" : "header__title-wrapper header__title-wrapperSmall"}>
                <h1 className="header__title">{helloText()}</h1>
                <Link className="header__title-link" to="/pages/about">Подробнее о фонде</Link>
            </div>
        </header>
    );
};

export default withRouter(Header);
