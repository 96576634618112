import {
  FETCH_PROMOTION_ERROR,
  FETCH_PROMOTION_PAGES_COUNT,
  FETCH_PROMOTION_START,
  FETCH_PROMOTION_SUCCESS,
} from "../actions/actionTypes";

const initialState = {
  promotions: [],
  activePage: 1,
  countPages: 1,
  loading: false,
  error: null,
};

export default function promotionsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROMOTION_START:
      return {
        // ...state,
        loading: true,
      };
    case FETCH_PROMOTION_SUCCESS:
      return {
        ...state,
        promotions: action.promotions,
      };
    case FETCH_PROMOTION_PAGES_COUNT:
      return {
        ...state,
        loading: false,
        activePage: action.payload.activePage,
        countPages: action.payload.pages,
      };
    case FETCH_PROMOTION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
