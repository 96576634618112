import {
  FETCH_REVIEWS_ERROR,
  FETCH_REVIEWS_START,
  FETCH_REVIEWS_SUCCESS,
  SEND_REVIEWS_SUCCESS,
} from "../actions/actionTypes";

const initialState = {
  reviews: [],
  reviewsFormSended: false,
  loading: false,
  error: null,
};

export default function reviewsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_REVIEWS_START:
      return {
        // ...state,
        loading: true,
      };
    case FETCH_REVIEWS_SUCCESS:
      return {
        ...state,
        reviews: action.reviews,
        loading: false,
      };
    case FETCH_REVIEWS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case SEND_REVIEWS_SUCCESS:
      return {
        ...state,
        reviewsFormSended: true,
        error: null,
      };
    default:
      return state;
  }
}
