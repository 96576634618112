import { Component } from "react";
import { connect } from "react-redux";
import ItemNews from "../News/ShortNews";
import { fetchNews } from "../../store/actions/news";
import LoaderNews from "../Loader/LoaderNews";

class LastNews extends Component {
  componentDidMount() {
    this.props.fetchNews();
  }
  render() {
    return (
      <div>
        <h2>Последние новости</h2>
        <div className="Chronology-container">
          <div className="Chronology-wrapper">
            {this.props.loadingNews && this.props.news.length === 0 ? (
              <LoaderNews count={4} />
            ) : (
              <>
                {this.props.news.map((item) => {
                  return <ItemNews key={item.id} item={item} />;
                })}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    news: state.news.news || [],
    loadingNews: state.news.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchNews: () => dispatch(fetchNews(1, 4)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LastNews);
