import { Component } from "react";
import { Link } from "react-router-dom";
import SubscribeToEmail from "./SubscribeToEmail/SubscribeToEmail";

class Footer extends Component {
  render() {
    return (
      <div className="App-footer">
        <footer className="Footer-root Footer-expanded">
          <div className="Footer-container">
            <div className="Footer-nav">
              <div className="Footer-group">
                <h3 className="Footer-groupTitle">О фонде</h3>
                <ul className="Footer-groupList">
                  <li>
                    <Link to="/pages/about">Деятельность фонда</Link>
                  </li>
                  <li>
                    <Link to="/pages/osnfonda">Основатель фонда</Link>
                  </li>
                  <li>
                    <Link to="/pages/sovet">Попечительский совет</Link>
                  </li>
                  <li>
                    <Link to="/pages/needhelp">Как получить помощь</Link>
                  </li>
                  <li>
                    <Link to="/pages/contact">Контакты</Link>
                  </li>
                </ul>
              </div>
              <div className="Footer-group">
                <h3 className="Footer-groupTitle">Им нужна помощь</h3>
                <ul className="Footer-groupList">
                  <li>
                    <Link to="/they-need-help">Подопечные фонда</Link>
                  </li>
                  <li>
                    <Link to="/news?tag=Храмы">Храмы</Link>
                  </li>
                  <li>
                    <Link to="/we-helped">Мы помогли</Link>
                  </li>
                </ul>
              </div>
              <div className="Footer-group">
                <h3 className="Footer-groupTitle">Новости</h3>
                <ul className="Footer-groupList">
                  <li>
                    <Link to="/news">Новости фонда</Link>
                  </li>
                  <li>
                    <Link to="/news?tag=Юристы+разъясняют">
                      Юристы разъясняют
                    </Link>
                  </li>
                  <li>
                    <Link to="/news?tag=Полезно+знать">Полезно знать</Link>
                  </li>
                </ul>
              </div>
              <div className="Footer-group">
                <h3 className="Footer-groupTitle">Нам помогают</h3>
                <ul className="Footer-groupList">
                  <li>
                    <Link to="/pages/helpman">
                      Информация о спонсорах и благотворителях
                    </Link>
                  </li>
                </ul>
                <h3 className="Footer-groupTitle">
                  Получать информацию о деятельности фонда
                </h3>
                <SubscribeToEmail />
              </div>
              <div className="Footer-group">
                <h3 className="Footer-groupTitle">Отчеты</h3>
                <ul className="Footer-groupList">
                  <li>
                    <Link to="/reports/in">Отчеты о поступлениях</Link>
                  </li>
                  <li>
                    <Link to="/reports/out">Отчеты по выданным средствам</Link>
                  </li>
                  <li>
                    <Link to="/gratitude">Благодарности</Link>
                  </li>
                  <li>
                    <Link to="/reviews">Отзывы</Link>
                  </li>
                  <li>
                    <Link to="/news?tag=Жизнь+фонда">Жизнь фонда</Link>
                  </li>
                </ul>
              </div>
              <div className="Footer-group">
                <h3 className="Footer-groupTitle">Помочь</h3>
                <ul className="Footer-groupList">
                  <li>
                    <Link to="/pages/to-help">Способы пожертвования</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="Footer-copyright">
              <div className="Footer-copyrightGroup">
                <div className="Footer-copyrightItem reg-code_wrap">
                  <span className="reg-code">ЕГРЮЛ 1033600131234</span>
                </div>
                <div className="Footer-copyrightItem">
                  <span id="last-year"></span> Некоммерческий "Благотворительный
                  фонд Чижова"
                </div>
                <Link className="Link-root" to="/pages/about">
                  О фонде
                </Link>
                <div className="Footer-about"></div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
