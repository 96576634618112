import React from 'react';
import './MobileMenu.css';
import {Link} from "react-router-dom";

const MobileMenu = ({ onClose, isOpen, handlePopupClick }) => {

    const openPopup = () => {
        onClose()
        handlePopupClick()
    }

    return (
        <div className={isOpen ? "mobileMenu mobileMenu_opened" : "mobileMenu"} onClick={onClose} >
            <div className="mobileMenu__content" onClick={(e) => e.stopPropagation()}>
                <nav>
                    <ul className="mobileMenu__list">
                        <li><Link onClick={onClose} className="mobileMenu__link" to="/pages/about">О фонде</Link></li>
                        <li><Link onClick={onClose} className="mobileMenu__link" to="/they-need-help">Им нужна помощь</Link></li>
                        <li><Link onClick={onClose} className="mobileMenu__link" to="/news">Новости</Link></li>
                        <li><Link onClick={onClose} className="mobileMenu__link" to="/pages/helpman">Нам помогают</Link></li>
                        <li><Link onClick={onClose} className="mobileMenu__link" to="/reports/in">Отчеты</Link></li>
                        <li><Link onClick={onClose} className="mobileMenu__link" to="/pages/to-help">Помочь</Link></li>
                    </ul>
                </nav>
                <div className="mobileMenu__buttonsContainer">
                    <button onClick={openPopup} className="mobileMenu__button">Хочу помочь</button>
                    <Link onClick={onClose} to="/they-need-help" className="mobileMenu__button">Нужна помощь</Link>
                </div>
                <div className="mobileMenu__infoContainer">
                    <p className="mobileMenu__phone">+7 (473) 261-99-99</p>
                    <p className="mobileMenu__info">Служба поддержки 9:00-21:00 без выходных</p>
                </div>
            </div>
        </div>
    );
};

export default MobileMenu;