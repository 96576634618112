import axios from "axios";
import { QUERY_ROUTE, URL_BACKEND } from "../../utils/const";
import {
  FETCH_PROMOTION_ERROR,
  FETCH_PROMOTION_PAGES_COUNT,
  FETCH_PROMOTION_START,
  FETCH_PROMOTION_SUCCESS,
} from "./actionTypes";

export function fetchPromotions(page = 1, items = 10, finished = false) {
  return async (dispatch) => {
    dispatch(fetchPromotionsStart());
    try {
      const response = await axios.get(
        `${URL_BACKEND + QUERY_ROUTE.PROMOTIONS}${
          finished ? "/full" : ""
        }?pagesize=${items}&page=${page}`
      );

      dispatch(fetchPromotionsSuccess(response.data.models));
      dispatch(
        fetchPagesCount({
          pages: response.data.pages,
          activePage: page,
        })
      );
    } catch (error) {
      dispatch(fetchPromotionsError(error));
    }
  };
}

export function fetchPromotionsStart() {
  return {
    type: FETCH_PROMOTION_START,
  };
}

export function fetchPagesCount(payload) {
  return {
    type: FETCH_PROMOTION_PAGES_COUNT,
    payload,
  };
}

export function fetchPromotionsSuccess(promotions) {
  return {
    type: FETCH_PROMOTION_SUCCESS,
    promotions,
  };
}

export function fetchPromotionsError(error) {
  return {
    type: FETCH_PROMOTION_ERROR,
    error,
  };
}
