import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { URL_BACKEND } from "../../utils/const";
import { addSpaceToNumber } from "../../utils/utils";

const ItemHelpNeed = ({ item}) => {
  const [ref] = useInView({
    triggerOnce: true,
    rootMargin: "-100px 0px",
  });
  const cls = ["article-item"];
  return (
    <div
      ref={ref}
      className={cls.join(" ")}
    >
      <Link to={`/profile/${item.slug}`}>
        {item.image && (
          <div
            className="article-item__img"
            style={{
              backgroundImage: `url("${URL_BACKEND + item.image}")`,
            }}
          ></div>
        )}
      </Link>
      <div className="article-item__text">
        <div className="article-item__title">
          <h2>{item.fio}</h2>
        </div>
        <div className="article-item__description">
          {item.dia && (
            <p className="article-item__description-diagnosis">
              Диагноз: {item.dia}
            </p>
          )}

          {item.short && (
            <p className="article-item__description-text">{item.short}</p>
          )}
        </div>
      </div>
      <div className="article-item__footer">
        {item.status === 1 && (
          <div className="article-item__money">
            <span>
              <p className="article-item__money-recd-wrap">
                <span className="article-item__money-recd">
                  {addSpaceToNumber(item.money.toString())}
                </span>{" "}
                ₽
              </p>
              <p className="article-item__money-progress">
                {item.money === 0
                  ? 0
                  : Math.round((item.money * 100) / item.moneyneed)}
                %
              </p>
              <p>СОБРАНО</p>
            </span>
            <span>
              <p>
                <span className="article-item__money-need">
                  {addSpaceToNumber(item.moneyneed.toString())}
                </span>{" "}
                ₽
              </p>
              <p>НУЖНО</p>
            </span>
            <div
              className="article-item__progress-bar"
              style={{
                width: `${
                  item.money === 0 ? 0 : (item.money * 100) / item.moneyneed
                }%`,
              }}
            ></div>
          </div>
        )}

        <div className="article-item-buttons">
          <Link to={`/profile/${item.slug}`}>
            <div className="article-btn-details btn btn-primary">Подробнее</div>
          </Link>
          {item.status === 1 ? (
            <a href={item.payment_link ? item.payment_link : 'https://securecardpayment.ru/sc/PvQptRkqJPEiXVbL'}>
            <div className="article-donate btn btn-primary">Помочь</div>
            </a>
          ) : (
            <div>
              <div className="article-donate btn btn-primary">Помощь оказана</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemHelpNeed;
