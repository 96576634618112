import { Component } from "react";
import { Link } from "react-router-dom";
import { URL_BACKEND } from "../../utils/const";
import { getDateRussianLocalisation } from "../../utils/utils";

class ItemNews extends Component {
  getTags = (tags) => {
    if (tags) {
      const arrayTags = tags.split(",");
      return arrayTags.map((item) => {
        return (
          <span key={item} className="label label-info">
            {item}
          </span>
        );
      });
    }
    return null;
  };

  render() {
    const item = this.props.item;
    return (
      <div className="Chronology-item">
        <Link className="ChronologyItem-link" to={`/news/${item.slug}`}>
          <div className="ChronologyItem-root">
            <figure className="ChronologyItem-figure">
              {item.img_small && (
                <div
                  className="ChronologyItem-image"
                  style={{
                    backgroundImage: `url("${URL_BACKEND + item.img_small}")`,
                  }}
                ></div>
              )}
            </figure>
            <div className="ChronologyItem-body">
              <div className="ChronologyItem-header">
                <div className="ChronologyItem-tag">
                  {/* {item.tags.map((el) => (
                    <span key={el} className="label label-info">
                      {el}
                    </span>
                  ))} */}
                  {this.getTags(item.tags)}
                </div>
                <div className="ChronologyItem-title">
                  <h2>{item.title}</h2>
                </div>

                <div className="ChronologyItem-title">
                  <p>{item.text_mini}</p>
                </div>
              </div>
              <div className="ChronologyItem-footer">
                <div className="MetaItem-date small-muted">
                  <time className="Timestamp-date">
                    {getDateRussianLocalisation(item.date)}
                  </time>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

export default ItemNews;
