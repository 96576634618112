export const FETCH_PROMOTION_START = "FETCH_PROMOTION_START";
export const FETCH_PROMOTION_SUCCESS = "FETCH_PROMOTION_SUCCESS";
export const FETCH_PROMOTION_PAGES_COUNT = "FETCH_PROMOTION_PAGES_COUNT";
export const FETCH_PROMOTION_ERROR = "FETCH_PROMOTION_ERROR";

export const FETCH_NEWS_START = "FETCH_NEWS_START";
export const FETCH_NEWS_SUCCESS = "FETCH_NEWS_SUCCESS";
export const FETCH_NEWS_ITEM_SUCCESS = "FETCH_NEWS_ITEM_SUCCESS";
export const FETCH_NEWS_PAGES_COUNT = "FETCH_NEWS_PAGES_COUNT";
export const FETCH_NEWS_ERROR = "FETCH_NEWS_ERROR";

export const FETCH_REVIEWS_START = "FETCH_REVIEWS_START";
export const FETCH_REVIEWS_SUCCESS = "FETCH_REVIEWS_SUCCESS";
export const FETCH_REVIEWS_ERROR = "FETCH_REVIEWS_ERROR";
export const SEND_REVIEWS_SUCCESS = "SEND_REVIEWS_SUCCESS";

export const FETCH_REPORTS_START = "FETCH_REPORTS_START";
export const FETCH_REPORTS_SUCCESS = "FETCH_REPORTS_SUCCESS";
export const FETCH_REPORTS_ERROR = "FETCH_REPORTS_ERROR";
