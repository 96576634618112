import {Fragment} from "react";
import HelpNeed from "../components/Promotions/HelpNeed";

import LastNews from "../components/Home/LastNews";

import {ReactTitle} from "react-meta-tags";

const Home = () => {


    return (
        <Fragment>
            <ReactTitle title="Благотворительный фонд Чижова"/>
            <HelpNeed isMainPage={true}/>
            <hr/>
            <LastNews/>
            <br/>
            <hr/>
        </Fragment>
    );
}

export default Home;
