import { Link } from "react-router-dom";

const page404 = () => (
  <div className="page-not-found">
    <h2 className="page-not-found__title">Страница не найдена</h2>
    <p>Запрашиваемая страница не существует</p>
    <p>
      Возможно, она была удалена или в запросе был указан неверный адрес
      страницы
    </p>
    <p>Попробуйте вернуться
      <Link to="/">
        <strong> на главную </strong>
      </Link>
      или связаться с нами по телефону <strong>+7(473)261-99-99</strong>
    </p>
  </div>
);

export default page404;
