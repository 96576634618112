import Footer from "../../components/Footer/Footer";
import PaymentsPopup from "../../components/Popups/PaymentsPopup";
import HeaderNew from "../../components/Header/Header";
import MobileMenu from "../../components/MobileMenu/MobileMenu";

const {Component} = require("react");

class Layout extends Component {

    render() {
        return (
            <div>
                <HeaderNew handleMobileOpen={this.props.handleMobileOpen} handlePopupClick={this.props.handlePopupClick} windowWidth={this.props.windowWidth}/>
                <div id="wrapper" className="container">
                    <main>
                        {this.props.children}
                        <div className="push"></div>
                    </main>
                </div>
                <Footer/>
                <PaymentsPopup isOpen={this.props.isOpened} onClose={this.props.onClose}/>
                <MobileMenu handlePopupClick={this.props.handlePopupClick} isOpen={this.props.isMobileOpen} onClose={this.props.onClose}/>
            </div>
        );
    }
}

export default Layout;
