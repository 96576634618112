import { URL_BACKEND } from "./const";

export const addSpaceToNumber = (num) => {
  //  eslint-disable-next-line
  return num?.toString().replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + " ");
};

export function getActivePage(val) {
  const params = new URLSearchParams(val);
  return Number(params.get("page")) || 1;
};

export function getTag(val) {
  const params = new URLSearchParams(val);
  return params.get("tag") || "";
};

export function getDateRussianLocalisation(date) {
  if (!date) {
    return null;
  }

  if (date.includes(".")) {
    return date;
  }
  const oldDate = date.split("-");

  return `${oldDate[2]} ${getMonthToDate(oldDate[1])} ${oldDate[0]}`;
};

export function getDateFromUnix(unix) {
  const date = new Date(unix * 1000);
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

export function getMonthToDate(month) {
  switch (Number(month)) {
    case 1:
      return "января";
    case 2:
      return "февраля";
    case 3:
      return "марта";
    case 4:
      return "апреля";
    case 5:
      return "мая";
    case 6:
      return "июня";
    case 7:
      return "июля";
    case 8:
      return "августа";
    case 9:
      return "сентября";
    case 10:
      return "октября";
    case 11:
      return "ноября";
    case 12:
      return "декабря";

    default:
      return null;
  }
};

export function getMonthToString(month) {
  switch (Number(month)) {
    case 1:
      return "январь";
    case 2:
      return "февраль";
    case 3:
      return "март";
    case 4:
      return "апрель";
    case 5:
      return "май";
    case 6:
      return "июнь";
    case 7:
      return "июль";
    case 8:
      return "август";
    case 9:
      return "сентябрь";
    case 10:
      return "октябрь";
    case 11:
      return "ноябрь";
    case 12:
      return "декабрь";

    default:
      return null;
  }
};

export function getMonthToRoditelniiPadezh(month) {
  switch (Number(month)) {
    case 1:
      return "январе";
    case 2:
      return "феврале";
    case 3:
      return "марте";
    case 4:
      return "апреле";
    case 5:
      return "мае";
    case 6:
      return "июне";
    case 7:
      return "июле";
    case 8:
      return "августе";
    case 9:
      return "сентябре";
    case 10:
      return "октябре";
    case 11:
      return "ноябре";
    case 12:
      return "декабре";

    default:
      return null;
  }
};

export function pagination(num, limit, range = 2) {
  const arr = [];
  for (let i = 1; i <= limit; i++) {
    if (
      i <= range ||
      (i > num - range / 2 && i < num + range / 2) ||
      i > limit - range
    ) {
      if (arr[arr.length - 1] && i !== arr[arr.length - 1] + 1) arr.push("...");
      arr.push(i);
    }
  }

  return arr;
};

export function timestampToDate(timestamp) {
  let time = new Date(timestamp * 1000);
  return time.toLocaleString();
};

export function chunk(arr, size) {
  const result = [];

  for (let i = 0; i < Math.ceil(arr.length / size); i++) {
    result.push(arr.slice(i * size, i * size + size));
  }

  return result;
};

export function getAge(birthday) {
  const birthdayDate = new Date(birthday);
  const nowDate = new Date();
  const diff = nowDate - birthdayDate;

  return Math.floor(diff / 31557600000) || 0;
};

export function ageToStr(age) {
  let txt;
  let count = age % 100;
  if (count < 1) {
    return "Меньше года";
  } else if (count >= 5 && count <= 20) {
    txt = "лет";
  } else {
    count = count % 10;
    if (count === 1) {
      txt = "год";
    } else if (count >= 2 && count <= 4) {
      txt = "года";
    } else {
      txt = "лет";
    }
  }
  return age + " " + txt;
};

export function replaceImageSrc(value = "") {
  let html = document.createElement('html');
  html.innerHTML = value;
  Array.from(html.getElementsByTagName('img')).forEach((el) => {
    if (el.src.includes('https://fondchizhova.ru/')) {
      el.src = el.src.replace('https://fondchizhova.ru/', URL_BACKEND);
    }
  });

  return html.innerHTML;
};

export function donationMethodsToString(value) {
  switch (value) {
    case "schet":
      return "Поступление на расчетный счет";
    case "box":
      return "Благотворительные боксы";
    case "kassa":
      return "Поступление в кассу фонда";
    case "sms":
      return "Поступления через SMS 7522";
    case "qiwi":
      return "QIWI";

    default:
      return "Прочие поступления";
  }
};

export function generateArrayYears(startYear) {
  const arr = [];
  // console.log(Date.now());
  const yearNow = new Date().getFullYear();

  while (startYear <= yearNow) {
    arr.push(startYear);
    startYear++;
  }

  return arr.reverse();
};

export function cloudPay(payInfo) {
  return window.pay(payInfo);
};
