import axios from "axios";
import { QUERY_ROUTE, URL_BACKEND } from "../../utils/const";
import {
  FETCH_NEWS_ERROR,
  FETCH_NEWS_ITEM_SUCCESS,
  FETCH_NEWS_PAGES_COUNT,
  FETCH_NEWS_START,
  FETCH_NEWS_SUCCESS,
} from "./actionTypes";

export function fetchNews(page = 1, items = 10, tag = "") {
  return async (dispatch) => {
    dispatch(fetchNewsStart());
    try {
      const response = await axios.get(
        `${
          URL_BACKEND + QUERY_ROUTE.NEWS
        }?pagesize=${items}&page=${page}&tag=${tag}`
      );

      dispatch(fetchNewsSuccess(response.data.models));
      dispatch(
        fetchPagesCount({
          pages: response.data.pages,
          activePage: page,
        })
      );
    } catch (error) {
      dispatch(fetchNewsError(error));
    }
  };
}

export function fetchNewsById(slug) {
  return async (dispatch) => {
    dispatch(fetchNewsStart());
    try {
      const response = await axios.get(
        `${URL_BACKEND + QUERY_ROUTE.NEWS + slug}`
      );

      dispatch(fetchNewsItemSuccess(response.data.model));
    } catch (error) {
      dispatch(fetchNewsError(error));
    }
  };
}

export function fetchNewsStart() {
  return {
    type: FETCH_NEWS_START,
  };
}

export function fetchPagesCount(payload) {
  return {
    type: FETCH_NEWS_PAGES_COUNT,
    payload,
  };
}

export function fetchNewsItemSuccess(itemNews) {
  return {
    type: FETCH_NEWS_ITEM_SUCCESS,
    itemNews,
  };
}

export function fetchNewsSuccess(news) {
  return {
    type: FETCH_NEWS_SUCCESS,
    news,
  };
}

export function fetchNewsError(error) {
  return {
    type: FETCH_NEWS_ERROR,
    error,
  };
}
